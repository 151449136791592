import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import appsettings from './appsettings';

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
    this.oktaAuth = new OktaAuth({
      issuer: 'https://dev-792490.okta.com/oauth2/default',
      clientId: appsettings.oktaClientId,
      redirectUri: window.location.origin + '/login/callback',
    });
    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      // props.history?.replace(toRelativeUrl(originalUri || '/', window.location.origin));
      window.location.href = '/home';
    };
  }

  render() {
    return (
      <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri} >
        <Layout>
          <Routes>
            <Route path="/login/callback" element={<LoginCallback />} />
            {AppRoutes.map((route, index) => {
              const { path, element } = route;
              return <Route key={index} path={path} element={element} />;
            })}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Layout>
      </Security>
    );
  }
}
