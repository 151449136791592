import * as React from 'react';
import './css/Footer.css';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-content">
          <div className="brand">
            MyUnivers 2.0
          </div>
          <div className="copyright">
            © Copyright 2025 MyUnivers. All Rights Reserved
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
