import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
// @ts-ignore
import logBookImg from '../images/pilot-logbook.png'; import trackerUpImg from '../images/PCB_L80.png'; import trackerL80 from '../images/PCB_L80.png'; import trackerLora from '../images/PCB_Lora.png'; import cryptoPaymentStation from '../images/Crypto2CryptoReader.png'
import actions from '../actions/actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { UserAirport } from '../class/UserAirport';
import { Weather } from '../class/Weather';

interface Props {
  oktaAuth: any;
  authState: any;
  airportMetar: Array<any>;
  airportTaf: Array<any>;
  userAirportList: Array<UserAirport>;
  chatGptReview: any;
  checkUserId(p: any): void;
  checkMetar(p: any): void;
  checkTaf(p: any): void;
  getUserAirportList(token: any): void;
  getChatGptReview(p: any): void;
}

interface State {
  token: any;
}

const mapStateToProps = (state: any) => {
  return {
    airportMetar: state.airportMetar,
    airportTaf: state.airportTaf,
    userAirportList: state.userAirportList,
    chatGptReview: state.chatGptReview,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    checkUserId: (token: any) => dispatch<any>(actions.user.checkUserId(token)),
    checkMetar: (airport: string) => dispatch<any>(actions.weather.getMetar(airport)),
    checkTaf: (airport: string) => dispatch<any>(actions.weather.getTaf(airport)),
    getUserAirportList: (token: any) => dispatch<any>(actions.userAirport.getUserAirportList(token)),
    getChatGptReview: (weather: Weather) => dispatch<any>(actions.weather.getChatGptReview(weather)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(class Home extends Component<Props, State>{
  static displayName = Home.name;
  constructor(props: any) {
    super(props);
  }

  async componentDidMount() {
    while (!this.props.authState) {
      await new Promise(resolve => setTimeout(resolve, 100)); // Wait for 100 milliseconds
    }

    if (!this.props.authState?.isAuthenticated) {
      await this.props.checkMetar('ESOW');
      await this.props.checkTaf('ESOW');
    }
    else {
      this.setState({
        token: this.props.authState.accessToken.accessToken
      });
      this.props.checkUserId(this.props.authState.accessToken.accessToken);
      await this.props.getUserAirportList(this.props.authState.accessToken.accessToken);

      let airportCode;
      if (this.props.userAirportList.length > 0) {
        airportCode = this.props.userAirportList
          .map(airport => airport.airport?.airportCode) // Extract airport codes
          .filter(Boolean) // Filter out any undefined values
          .join(';'); // Join with commas
      } else {
        airportCode = 'ESOW';
      }
      await this.props.checkMetar(airportCode);
      await this.props.checkTaf(airportCode);

    }
  }

  async getChatGptReview() {
    // //If the TAF or METAR has changed we query chatGPT with new values
    if (localStorage.getItem('METAR') != this.props.airportMetar[0]?.rawOb || localStorage.getItem('CHATGPTREVIEW')=="") {
      console.log("WE HAVE A METAR, let's ask CHATGPT");

      localStorage.setItem('TAF', this.props.airportTaf[0].rawTAF);
      localStorage.setItem('METAR', this.props.airportMetar[0].rawOb);

     this.props.getChatGptReview(new Weather(this.props.airportMetar[0]?.rawOb, this.props.airportTaf[0]?.rawTAF, ''));
    }
  }

  componentDidUpdate(prevProps: any) {

    if (this.props.airportMetar !== prevProps.airportMetar && this.props.airportMetar.length > 0) {
      this.getChatGptReview();
    }

    if (this.props.airportTaf !== prevProps.airportTaf && this.props.airportTaf.length > 0) {
      this.getChatGptReview();
    }

    if (this.props.chatGptReview !== prevProps.chatGptReview) {
      localStorage.setItem('CHATGPTREVIEW', this.props.chatGptReview);
    }
  }

  render() {
    return (
      <section className="py-5 " style={{ height: 600 }}>
        <div className="container">

          <div className='row'>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <h1 className="display-5">E - Logbook</h1>
                <p className="lead">Create your list of Aircraft and start login your fly</p>
                {/* <hr className="my-4"> */}
                <p className="card-text">
                  <Link to="/LogBookAircraft" className="btn btn-success m-3"><span><i className="fa fa-file-text"></i> New Log</span></Link>
                  <Link to="/LogBookAdmin" className="btn btn-primary m-3"><span><i className="fa fa-wrench"></i> Aircrafts & Airfields</span></Link>
                </p>
              </div>
            </div>
          </div>

          <div className='row mt-4'>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <h1 className="display-5">Weather Information</h1>
                <p className="lead"></p>

                <h4>METAR</h4>
                {this.props.airportMetar.length > 0 ? (
                  <div>
                    {this.props.airportMetar?.map((metar, index) => (
                      <h5 key={index}> <i style={{ color: 'orange' }} className="fa-solid fa-star"></i> {metar.rawOb}</h5>
                    ))}
                  </div>
                ) : (
                  <div><h6></h6></div>
                )}
                 <hr className="my-4"></hr>
                <h4>TAF</h4>
                {this.props.airportTaf.length > 0 ? (
                  <div className='mb-3'>
                    {this.props.airportTaf?.map((taf, index) => (
                      <h5 key={index} style={{ color: '#a7abb0' }}> <i style={{ color: 'orange' }} className="fa-solid fa-star"></i> {taf.rawTAF}</h5>
                    ))}
                  </div>
                ) : (
                  <div><h6></h6></div>
                )}

                <hr className="my-4"></hr>
                <h4>ChatGPT Review</h4>
                {this.props.airportMetar.length > 0 ? (
                  <div className='mb-3'>
                      <h5 style={{ color: '#a7abb0' }}> <i style={{ color: 'orange' }} className="fa-solid fa-comments"></i> {localStorage.getItem('CHATGPTREVIEW')}</h5>
                  </div>
                ) : (
                  <div><h6></h6></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}));
