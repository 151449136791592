import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as actionCreator from '../actions/actions';
import EntryPopup from "./popup/SmartHouseEntryPopup";
import KeyPopup from "./popup/SmartHouseKeyPopup";
import { withOktaAuth } from '@okta/okta-react';
import * as moment from 'moment';
import Toggle from 'react-toggle';
import ConfirmPopup from './popup/ConfirmPopup';

interface Props {
    oktaAuth: any;
    authState: any;
    navigation: any;

    auth?: any;
    flatList: Array<any>;
    keyList: Array<any>;
    entryList: Array<any>;
    getFlatList(token: any): void;
    getKeyList(token: any): void;
    getEntryList(token: any): void;
    deleteEntry(token: any, selectedEntry: any): void;
}

interface State {
    selectedEntry: any,
    selectedKey: any,
    showPopupEntry: boolean,
    showPopupKey: boolean,
    token: any,
    popupTitle: string,
    isTrackerNotSaved: boolean;
    errorMessage: string;
    isUserSaved: boolean;
    isUserUpdated: boolean;
    tokenUser: any,
    isSaved: boolean;
    isDeleted: boolean;
    showConfirmPopup: boolean;
}

const mapStateToProps = (state: any) => {
    return {
        flatList: state.flatList,
        keyList: state.keyList,
        entryList: state.entryList,
        isSaved: state.isFlatKeySaved,
        isDeleted: state.isDeleted,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getFlatList: () => dispatch<any>(actionCreator.default.smartHouse.getFlatList()), //no tocken for this one because we use it unlogged
        getKeyList: (token: any) => dispatch<any>(actionCreator.default.smartHouse.getKeyList(token)),
        getEntryList: (token: any) => dispatch<any>(actionCreator.default.smartHouse.getEntryList(token)),
        deleteEntry: (token: any, selectedEntry: any) => dispatch<any>(actionCreator.default.smartHouse.deleteEntry(token, selectedEntry)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(class SmartHouseAdmin extends React.Component<Props, State> {
    static displayName = SmartHouseAdmin.name;
    constructor(props: any) {
        super(props);

        this.state = {
            showPopupEntry: false,
            showPopupKey: false,
            token: null,
            tokenUser: null,
            popupTitle: "",
            selectedEntry: {},
            selectedKey: {},
            isTrackerNotSaved: false,
            errorMessage: "",
            isUserSaved: false,
            isUserUpdated: false,
            isSaved: false,
            isDeleted: false,
            showConfirmPopup: false
        };
    };

    async componentDidMount() {
        while (!this.props.authState) {
            await new Promise(resolve => setTimeout(resolve, 100)); // Wait for 100 milliseconds
        }

        try {
            if (!this.props.authState?.isAuthenticated) {
                this.props.oktaAuth.signInWithRedirect({ originalUri: "/" });
            }
            else {
                this.props.getFlatList(this.props.authState.accessToken.accessToken);
                this.props.getKeyList(this.props.authState.accessToken.accessToken);
                this.props.getEntryList(this.props.authState.accessToken.accessToken);
                this.setState({
                    token: await this.props.authState.accessToken.accessToken
                })
            }
        } catch (err) {
            // handle error as needed
        }
    }

    handleAddEntry = () => {
        this.setState({
            popupTitle: "New Entry",
            selectedEntry: {
                entryId: 0,
                entryName: '',
                entryCheckIn: '',
                entryCheckOut: '',
                entryCode: '',
                isDoorCodeValid: true
            },
            showPopupEntry: true
        });
    }

    handleEditEntry = (entry: any) => {
        this.setState({
            popupTitle: "Edit Entry",
            selectedEntry: entry,
            showPopupEntry: true
        });
    }

    handleDeleteEntry = (entry: any) => {
        this.setState({
            selectedEntry: entry,
            showConfirmPopup: true
        });
    }

    handleConfirmDelete = (confirmDelete: boolean) => {
        if (confirmDelete) {
            this.setState({ showConfirmPopup: false });
            this.props.deleteEntry(this.state.token, this.state.selectedEntry);
        }
        else {
            this.setState({
                showConfirmPopup: false,
            });
        }
    }

    handleAddKey = () => {
        this.setState({
            popupTitle: "New Tag",
            selectedKey: {
                keyId: 0,
                keyDescription: '',
                keyCode: '',
                keyIsValid: true
            },
            showPopupKey: true
        });
    }

    handleEditKey= (tag: any) => {
        console.log(tag);
        this.setState({
            popupTitle: "Edit Tag",
            selectedKey: tag,
            showPopupKey: true
        });
    }

    handleCloseEntryPopup = (data: string) => {
        this.setState({
            showPopupEntry: false,
        });
    }

    handleCloseKeyPopup = (data: string) => {
        this.setState({
            showPopupKey: false,
        });
    }

    render() {

        let displayEntryList = this.props.entryList.map((item, index) => (
            <tr key={index}>
                <td>{item.entryName}</td>
                <td>{item.flat?.flatName || "N/A"}</td>
                <td>{moment.parseZone(item.entryCheckIn).format('DD/MM/YYYY HH:MM') || "N/A"}</td>
                <td>{moment.parseZone(item.entryCheckOut).format('DD/MM/YYYY HH:MM') || "N/A"}</td>
                <td>{item.keyId}</td>
                <td>{item.key?.keyDescription || "N/A"}</td>
                <td>{item.entryCode || "N/A"}</td>
                <td>
                    <button className="btn" onClick={() => this.handleDeleteEntry(item)} style={{ margin: 0, padding: 0 }}><span style={{ color: "orangered" }}><i className="fa fa-times"></i></span></button>
                    <button className="btn" onClick={() => this.handleEditEntry(item)}><span style={{ color: "green" }}><i className="fas fa-edit"></i></span></button></td>
            </tr>
        ));

        let displayKeyList = this.props.keyList.map((item, index) => (
            <tr key={index}>
                <td>{item.keyId}</td>
                <td>{item.keyCode}</td>
                <td>{item.keyDescription}</td>
                <td><button className="btn" onClick={() => this.handleEditKey(item)}><span style={{ color: "green" }}><i className="fas fa-edit"></i></span></button></td>
                <td>
                    <Toggle style={{ height: 10 }} disabled
                        id='cheese-status'
                         defaultChecked={item.keyIsActive}
                    // onChange={() => this.handleDesactivateUser(item)}
                    />
                </td>

            </tr>
        ));

        let displayFlatList = this.props.flatList.map((item, index) => (
            <tr key={index}>
                <td>{item.flatName}</td>
                <td>{item.flatDescription}</td>
                <td>
                    <Toggle style={{ height: 10 }} disabled
                        id='cheese-status'
                        defaultChecked={item.flatIsDoorOpen}
                    // onChange={() => this.handleDesactivateUser(item)}
                    />
                </td>
                <td><button className="btn" onClick={() => this.handleEditEntry(item)}><span style={{ color: "green" }}><i className="fas fa-edit"></i></span></button></td>
            </tr>
        ));

        return (

            <div>
                {!this.state.token ? <div></div> :
                    <div>
                        <div className='row'>
                            <div className='col-12'>
                                <br ></br>
                                <div >
                                    <button style={{ float: "left",marginRight:"4px" }} type="button" className="btn btn-success btn-sm" onClick={this.handleAddEntry}><span><i className="fas fa-edit"></i></span> Add new entry</button>
                                    <button style={{ float: "left" }} type="button" className="btn btn-success btn-sm" onClick={this.handleAddKey}><span><i className="fas fa-edit"></i></span> Add new Tag</button>
                                    {this.state.isSaved && <div style={{ float: "right", height: "32px", padding: "3px" }} className="alert alert-success" role="alert"> Saved!</div>}
                                    {this.state.isDeleted && <div style={{ float: "right", height: "32px", padding: "3px" }} className="alert alert-success" role="alert"> Deleted!</div>}
                                </div>

                                <br /><br />
                                <table className="table table-sm table-bordered" >
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">For</th>
                                            <th scope="col">Flat</th>
                                            <th scope="col">Check In</th>
                                            <th scope="col">Check out</th>
                                            <th scope="col">Key</th>
                                            <th scope="col">Key type</th>
                                            <th scope="col">Door code</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayEntryList}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <br ></br>
                                <div >
                                    {/* <button style={{ float: "left" }} type="button" className="btn btn-success btn-sm" onClick={this.handleAddUser}><span><i className="fas fa-edit"></i></span> Add new flat</button> */}
                                    {this.state.isUserSaved && <div style={{ float: "right", height: "32px", padding: "3px" }} className="alert alert-success" role="alert"> New used added!</div>}
                                    {this.state.isUserUpdated && <div style={{ float: "right", height: "32px", padding: "3px" }} className="alert alert-success" role="alert"> User updated!</div>}
                                    {/* {this.state.isUserNotSaved && <div style={{ float: "right", height: "32px", padding: "3px" }} className="alert alert-danger alert-sm" role="alert"> {this.state.errorMessage}</div>} */}
                                </div>

                                <br /><br />
                                <table className="table table-sm table-bordered" >
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Open/close</th>
                                            <th scope="col">Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayFlatList}
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-6'>
                                <br ></br>
                                <div >
                                    {/* <button style={{ float: "left" }} type="button" className="btn btn-success btn-sm" onClick={this.handleAddUser}><span><i className="fas fa-edit"></i></span> Add new key</button> */}
                                    {this.state.isUserSaved && <div style={{ float: "right", height: "32px", padding: "3px" }} className="alert alert-success" role="alert"> New used added!</div>}
                                    {this.state.isUserUpdated && <div style={{ float: "right", height: "32px", padding: "3px" }} className="alert alert-success" role="alert"> User updated!</div>}
                                    {/* {this.state.isUserNotSaved && <div style={{ float: "right", height: "32px", padding: "3px" }} className="alert alert-danger alert-sm" role="alert"> {this.state.errorMessage}</div>} */}
                                </div>

                                <br /><br />
                                <table className="table table-sm table-bordered" >
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">key Nb</th>
                                            <th scope="col">Code</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Edit</th>
                                            <th scope="col">Activated</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayKeyList}
                                    </tbody>
                                </table>
                            </div>

                        </div>

                        <EntryPopup
                            show={this.state.showPopupEntry}
                            popupTitle={this.state.popupTitle}
                            selectedEntry={this.state.selectedEntry}
                            hide={this.handleCloseEntryPopup}
                            token={this.state.token}
                            isDoorCodeValide={true} />

                        <KeyPopup
                            show={this.state.showPopupKey}
                            popupTitle={this.state.popupTitle}
                            selectedKey={this.state.selectedKey}
                            hide={this.handleCloseKeyPopup}
                            token={this.state.token} />


                        <ConfirmPopup show={this.state.showConfirmPopup} hide={this.handleConfirmDelete} title="Delete Entry" content="Do you really want to delete this Entry?" />
                    </div>}
            </div>

        )
    }
}))