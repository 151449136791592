import * as React from 'react';
import { Modal, Button } from "react-bootstrap";
import { connect } from 'react-redux';
import * as actionCreator from '../../actions/actions';
import { Dispatch } from 'redux';
import Toggle from 'react-toggle';

interface State {
    keyId: any;
    keyDescription: any;
    keyCode: any;
    keyIsActive: boolean;
}

interface Props {
    popupTitle: string,
    selectedKey: any,
    token: any,
    show: boolean,
    hide(error: string): void,
    saveKey(token: any, Key: any): void;
    updateKey(token: any, Key: any): void;
}

const mapStateToProps = (state: any) => {
    return {
        //KeyId: state.entryId,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        saveKey: (token: any, Key: any) => dispatch<any>(actionCreator.default.smartHouse.saveKey(token, Key)),
        updateKey: (token: any, Key: any) => dispatch<any>(actionCreator.default.smartHouse.updateKey(token, Key)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(class SmartHouseKeyPopup extends React.Component<Props, State> {
    private socket: any;

    constructor(props: any) {
        super(props)
        this.state = {
            keyId: 0,
            keyDescription: '',
            keyCode: '',
            keyIsActive: false,
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.selectedKey !== prevProps.selectedKey && this.props.selectedKey) {
            this.setState((prevState) => ({
                ...prevState,
                keyId: this.props.selectedKey.keyId,
                keyDescription: this.props.selectedKey.keyDescription,
                keyCode: this.props.selectedKey.keyCode,
                keyIsActive: Boolean(this.props.selectedKey.keyIsActive),
            }));
        }
    }

    handleChange = (e: any) => {
        this.setState({
            [e.target.id]: e.target.value
        } as any);
    }

    handleBoolChange = (e: any) => {
        const { id, type, checked, value } = e.target;
        this.setState({
            [id]: type === 'checkbox' ? checked : value, // Use `checked` for toggles/checkboxes
        } as any);
    };

    handleSaveKey = (e: any) => {
        e.preventDefault();
        var myKey: any = ({
            KeyId: this.state.keyId,
            KeyDescription: this.state.keyDescription,
            KeyCode: this.state.keyCode,
            keyIsActive: this.state.keyIsActive,
        });
        console.log(myKey);
        if (myKey.KeyId != undefined) {
            this.props.updateKey(this.props.token, myKey);
        }
        else {
            console.log(myKey);
            this.props.saveKey(this.props.token, myKey);
        }

        this.props.hide("");
    }

    render() {
        return (
            <div>
                <Modal show={this.props.show} onHide={() => this.props.hide("")}>
                    <Modal.Header closeButton style={{ backgroundColor: '#2c2c2c' }}>
                        <Modal.Title >{this.props.popupTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#2c2c2c' }}>
                        <form id="newKeyForm" className="form-signin" onSubmit={this.handleSaveKey}>
                            <input id="KeyId" value={this.state.keyId} type="text" className="form-control" readOnly hidden></input>

                            <div className="form-label-group">
                                <label>Key Code</label>
                                <input id="keyCode" value={this.state.keyCode} type="text" className="form-control" placeholder="Key code" required onChange={this.handleChange}></input>
                            </div>


                            <div className="form-label-group">
                                <label>Description</label>
                                <input id="keyDescription" value={this.state.keyDescription} type="text" className="form-control" placeholder="Key description" required onChange={this.handleChange}></input>
                            </div>

                            <Toggle
                                style={{ height: 10 }}
                                id="keyIsActive"
                                checked={this.state.keyIsActive} // Controlled component
                                onChange={this.handleBoolChange}    // Properly bind the handler
                            />

                        </form>
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: '#2c2c2c' }}>
                        <Button variant="secondary" onClick={() => this.props.hide("")}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" form="newKeyForm" >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
})