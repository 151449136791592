import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Footer from './../components/Footer';
import './css/Layout.css'; // Import CSS file for styling

interface Props {
  children?: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  return (
    <div className="layout">
      <NavMenu />
      <main className="content">
        <Container>
          {children}
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export { Layout };
