import axios from 'axios';
import appsettings from '../appsettings';
const apiUrl = appsettings.serverBaseUrl + '/api/smartHouse/';    

export const getEntryList = (accessToken: any) =>{
  return async (dispatch  :any) =>{
    try{
      //We are logged in the API so we don't need to pass again the userId
      const res = await axios.get<any>(apiUrl + "GetEntryList/", {headers: {Authorization: 'Bearer ' + accessToken}});
      return dispatch(getEntryListSuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const getEntryListSuccess = (data :any) => {
  return {
    type: "SH_ENTRY_LIST",
    payload: data
  }
 }

export const getFlatList = () =>{
  return async (dispatch  :any) =>{
    try{
      //We are logged in the API so we don't need to pass again the userId
      const res = await axios.get<any>(apiUrl + "GetFlatList");
      return dispatch(getFlatListSuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const getFlatListSuccess = (data :any) => {
  return {
    type: "SH_FLAT_LIST",
    payload: data
  }
 }

 export const checkCode = (accessToken: any, user :any) =>{
  return async (dispatch  :any) =>{
    try{
      const res = await axios.post<any>(apiUrl + "CheckCode/",user, {headers: {Authorization: 'Bearer ' + accessToken}});
      return dispatch(checkCodeSuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const checkCodeSuccess = (data :any) => {
  return {
    type: "SH_CHECK_CODE",
    payload: data
  }
 }

 export const getKeyList = (accessToken: any) =>{
  return async (dispatch  :any) =>{
    try{
      //We are logged in the API so we don't need to pass again the userId
      const res = await axios.get<any>(apiUrl + "GetKeyList/", {headers: {Authorization: 'Bearer ' + accessToken}});
      return dispatch(getKeyListSuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const getKeyListSuccess = (data :any) => {
  return {
    type: "SH_KEY_LIST",
    payload: data
  }
 }

 ////-----------Save Entry----------------------
 export const saveEntry = (accessToken: any, entry :any) =>{
  return async (dispatch  :any) =>{
    try{
      const res = await axios.post<any>(apiUrl + "SaveEntry/",entry, {headers: {Authorization: 'Bearer ' + accessToken}});
      dispatch(showSavedLabel());
      return dispatch(saveEntrySuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const saveEntrySuccess = (data :any) => {
  return {
    type: "SH_SAVE_ENTRY",
    payload: data
  }
 }

 export const showSavedLabel = () =>{
  return async (dispatch  :any) =>{
    setTimeout(() => {
        dispatch(hideSavedLabel());
    }, 3000);
  }
}

export const hideSavedLabel = () =>{
  return {
    type:"SH_SAVE_LABEL_SHOW",
    payload: false
  }
}

////-----------Delete Entry----------------------
export const deleteEntry = (accessToken: any, entry :any) =>{
  return async (dispatch  :any) =>{
    try{
      const res = await axios.post<any>(apiUrl + "DeleteEntry/",entry, {headers: {Authorization: 'Bearer ' + accessToken}});
      dispatch(showDeleteLabel());
      return dispatch(deleteEntrySuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const deleteEntrySuccess = (data :any) => {
  return {
    type: "SH_DELETE_ENTRY",
    payload: data
  }
 }

 export const showDeleteLabel = () =>{
  return async (dispatch  :any) =>{
    setTimeout(() => {
        dispatch(hideDeletedLabel());
    }, 3000);
  }
}

export const hideDeletedLabel = () =>{
  return {
    type:"SH_DELETE_LABEL_SHOW",
    payload: false
  }
}

 ////-----------Update Entry----------------------
 export const updateEntry = (accessToken: any, entry :any) =>{
  return async (dispatch  :any) =>{
    try{
      const res = await axios.post<any>(apiUrl + "UpdateEntry/",entry, {headers: {Authorization: 'Bearer ' + accessToken}});
      dispatch(showUpdateLabel());
      return dispatch(updateEntrySuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const updateEntrySuccess = (data :any) => {
  return {
    type: "SH_UPDATE_ENTRY",
    payload: data
  }
 }

 export const showUpdateLabel = () =>{
  return async (dispatch  :any) =>{
    setTimeout(() => {
        dispatch(hideUpdateLabel());
    }, 3000);
  }
}

export const hideUpdateLabel = () =>{
  return {
    type:"SH_UPDATE_LABEL_SHOW",
    payload: false
  }
}

////-----------Open door with code----------------------

export const openDoorWithCode = (code: any) =>{
  return async (dispatch  :any) =>{
    try{
      //We are logged in the API so we don't need to pass again the userId
      const res = await axios.get<any>(apiUrl + "OpenDoorWithCode/" + code);
      return dispatch(openDoorSuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const openDoorSuccess = (data :any) => {
  return {
    type: "SH_OPEN_DOOR_WITH_CODE",
    payload: data
  }
 }

 ////-----------Save Key----------------------
 export const saveKey = (accessToken: any, key :any) =>{
  return async (dispatch  :any) =>{
    try{
      const res = await axios.post<any>(apiUrl + "SaveKey/",key, {headers: {Authorization: 'Bearer ' + accessToken}});
      dispatch(showSavedLabel());
      return dispatch(saveKeySuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const saveKeySuccess = (data :any) => {
  return {
    type: "SH_SAVE_KEY",
    payload: data
  }
 }

  ////-----------Update Key----------------------
  export const updateKey = (accessToken: any, key :any) =>{
    return async (dispatch  :any) =>{
      try{
        const res = await axios.post<any>(apiUrl + "UpdateKey/",key, {headers: {Authorization: 'Bearer ' + accessToken}});
        dispatch(showUpdateLabel());
        return dispatch(updateKeySuccess(res.data));
      }
      catch (error) {
        throw (error)
      }
    }
   }
   
   export const updateKeySuccess = (data :any) => {
    return {
      type: "SH_UPDATE_KEY",
      payload: data
    }
   }
